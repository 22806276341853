import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Banner() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const setting1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    rtl: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <section className="new_section section_p pt-md-5 pt-3">
        <div className="container-fluid mt-1">
          <div className="row align-items-center   text-md-start">
            <div className="  col-6 ">
              <p className="h5 color5 fw300 re14" data-aos="fade-right">
                //UKCCOIN PHASE 1
              </p>
            </div>
            <div
              className="col-md-6 m-auto col-6 text-end position-relative"
              data-aos="fade-left"
            >
              <img
                src="/assets/img/cricket.png"
                alt="cricket"
                className="img-fluid "
              />
              {/* <div className="cricket_btn">
                <span className="fmenda">CRICKET MEETS CRYPTO</span>
                THE ULTIMATE CRYCKET FAN TOKEN
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="hero position-relative">
        <img
          src="/assets/img/hero.png"
          alt="cricket"
          className="w100"
          data-aos="fade-up"
        />
        <img
          src="/assets/img/u_big.png"
          alt="u"
          className="position-absolute u_big"
          data-aos="fade-left"
        />
        <img
          src="/assets/img/cointop.png"
          alt="u"
          className="position-absolute cointop"
          data-aos="fade-left"
        />
        <div className="container-fluid pt-5">
          <p className="fw300 mb-4 h5" data-aos="fade-right">
            UKCCOIN isn’t just a<br /> cryptocurrency; it’s a revolution in
            <br /> fan engagement.
          </p>
          <a href="/#buysell" className="btn w_btn" data-aos="fade-right">
            <span className="d-flex align-items-center">
              Buy now <i className="bi bi-arrow-right-short b_icon"></i>
            </span>{" "}
          </a>
        </div>
        <div className="big_b_conntent" data-aos="fade-right">
          <div className="container-fluid">
            <p className="MendaMed color5 h4">
              Designed exclusively for cricket lovers, <br />
              UKCCOIN transforms how <br />
              fans interact with the game
            </p>
            <div className="d-flex ttu max_h5 mt-md-5 mt-4">
              <h6>Mass & In-Person Events </h6>
              <h6>Conreferces & Workshops </h6>
              <h6>Commercial & Promo Shows</h6>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative ">
        <div className="scrool_logo1 position-absolute MendaMed">
          <Slider {...setting1}>
            <h5 className="s_line1 m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
          </Slider>
        </div>
      </section>
      <section className="position-relative ">
        <div className="scrool_logo2  MendaMed">
          <Slider {...settings}>
            <h5 className="s_line1 m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
            <h5 className="m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
            <h5 className="m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Banner;
