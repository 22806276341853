import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";

function Header() {
  const {authenticated}=useAuth()

  return (
    <>
      <nav className="navbar navbar-expand-lg nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/"> 
            <img src="/assets/img/logo.svg" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname==="/"?"active":""}`} href="/"> 
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://ukc-coin.gitbook.io/ukc-whitepaper" target="_blank">
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#tokenomics">
                  Tokenomics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#tokenomics">
                  Roadmap
                </a>
              </li>
              {authenticated && <li className="nav-item">
                <Link className={`nav-link ${window.location.pathname==="/transaction"?"active":""}`} to="/transaction">
                  Transaction
                </Link>
              </li>}
              
            </ul>
          </div>
          <div className="right_btn">
            <a href="https://x.com/ukc_coin" className="btn btn_border">
              <i className="bi bi-bi bi-twitter-x"></i>
            </a>
            <a href="#" className="btn btn_border">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="https://t.me/UKCCoinOfficialGroup" className="btn btn_border">
              <i className="bi bi-telegram"></i>
            </a>
            <a href="/#buysell" className="btn btn_r_t">
              BUY NOW
            </a>
        
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
