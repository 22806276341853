import { checkUserApi, userRegisterApi, userTempWalletAddressApi } from "../components/constant/api";
import { myApi } from "./api";

export const userApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setUserRegister: builder.mutation({
      query: (post) => ({
        url: userRegisterApi,
        method: "POST",
        body:post
      }),
    }),
    setCheckUser: builder.mutation({
      query: (post) => ({
        url: checkUserApi,
        method: "POST",
        body:post
      }),
    }),
    setTempUserWallet: builder.mutation({
      query: (post) => ({
        url: userTempWalletAddressApi,
        method: "POST",
        body:post
      }),
    }),
  }),
});

export const {useSetCheckUserMutation,useSetTempUserWalletMutation,useSetUserRegisterMutation} = userApi;