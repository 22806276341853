import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";

function LedBy() {
  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true, // Add this property
    centerPadding: "0", // Optional: Adjust padding if needed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };

  const slides = [
    "/assets/img/led3.png", 
    "/assets/img/led1.png",
    "/assets/img/led2.png",
    "/assets/img/led3.png", 
    "/assets/img/led1.png",
    "/assets/img/led2.png",
  ];

  const middleIndex = Math.floor(slides.length / 2);

  return (
    <>
      <section className="p70 ledend_sec text-center">
        <div className="container">
          <h1 className="text-center fmenda" data-aos="fade-up">
            <span className="d-inline-flex align-items-center">
              LED BY
              <img
                className="gcard ms-3"
                src="/assets/img/gcard.png"
                alt="gcard"
              />
            </span>
            <br />
            <span className="legend">LEGENDS</span>
          </h1>
          <div className="col-md-8 m-auto">
          <div className="" data-aos="fade-up">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true} // Enable infinite looping
              slidesPerView={"auto"}
              initialSlide={middleIndex} // Start with the middle image
               autoplay={{
                 delay: 3000, // 3 seconds delay between slides
                 disableOnInteraction: true, // Continue autoplay after user interaction
               }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              modules={[EffectCoverflow, Autoplay]} // Include EffectCoverflow and Autoplay modules
              className="swiper_container"
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div>
                    <img src={slide} alt={`slide_image_${index}`} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* <Slider {...setting}>
              <img src="/assets/img/led3.png" alt="gcard" />

              <img src="/assets/img/led1.png" alt="gcard" />

              <img src="/assets/img/led2.png" alt="gcard" />

              <img src="/assets/img/led1.png" alt="gcard" />
            </Slider> */}
          </div>
          </div>
          <div className="text-center mt-4 ">
            <a href="#" className="btn b_btn ttu">
              <span className="d-flex align-items-center">
                EXPLORE now <i className="bi bi-arrow-right-short b_icon"></i>
              </span>{" "}
            </a>

            <h4 className="mt-4 mt-md-5 MendaMed">
              The greatest names of the sport, representing their
              <br /> respective countrymen, are at the heart of UKCCOIN.
            </h4>
          </div>
        </div>
      </section>
    </>
  );
}

export default LedBy;
