import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AsSeen() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
     
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <section className="text-center asseen pt-5">
        <h4 className="mb-4 fmenda">AS SEEN ON</h4>
        <div className="asseen_in" >
          <Slider {...settings} >
            <div data-aos="fade-up" style={{ margin: "0 10px", display: "inline-block" }}>
              <img src="/assets/img/seen1.png" alt="logso" />
            </div>
            <div data-aos="fade-up" style={{ margin: "0 10px", display: "inline-block" }}>
              <img src="/assets/img/seen2.png" alt="logso" />
            </div>
            <div data-aos="fade-up" style={{ margin: "0 10px", display: "inline-block" }}>
              <img src="/assets/img/seen3.png" alt="logso" />
            </div>
            <div data-aos="fade-up" style={{ margin: "0 10px", display: "inline-block" }}>
              <img src="/assets/img/seen4.png" alt="logso" />
            </div>
            <div data-aos="fade-up" style={{ margin: "0 10px", display: "inline-block" }}>
              <img src="/assets/img/seen5.png" alt="logso" />
            </div>
          </Slider>
        </div>
      </section>
      <section className="legends pb-4">
        <div className="container-fluid" >
        <div className="row">
          <div className="col-md-4 pe-md-5">
            <div className="legends_left mt-5" data-aos="zoom-out-right">
              <p>
                UKC has garnered great success from its first edition that took
                place at the Coca Cola Arena in Dubai, featuring kricketing
                stars like Kevin Petersen, Chris Gayle, Andre Russel, Yuvraj
                Singh, Eoin Morgan and much more! We are hyped for the second
                edition to be coming soon and hosted at the Etihad Stadium, Abu
                Dhabi.
              </p>
            </div>
            <img className="coin1" src="/assets/img/coin1.png" alt="legends_right" data-aos="zoom-in" />

            <a href="#" className="MendaMed text-black d-flex align-items-center">
              {" "}
              Watch UKC at the Coca Cola Arena{" "}
              <i className="bi bi-arrow-right-short d_icon"></i>{" "}
            </a>
          </div>
          <div className="col-md-8 ">
            <div className="legends_right" data-aos="fade-left">
              <img
                className="img-fluid w100"
                src="/assets/img/legends_right.png"
                alt="legends_right"
              />
            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default AsSeen;
