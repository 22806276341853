import React, { useEffect } from "react";
import Footer from "../../widgets/Footer";
import AsSeen from "./AsSeen";
import Banner from "./Banner";
import BuySell from "./BuySell";
import Faq from "./Faq";
import MiddleBanner from "./MiddleBanner";
import Signup from "./Signup";
import YouTubeLink from "./YouTubeLink";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../../widgets/Header";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash == "") {
      scrollTop();
    }
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 100,
    });
  });
  return (
    <>
      <Header />
      <div className="ovh">
        <Banner />
        <BuySell />  
      </div>
      <AsSeen />
      <YouTubeLink />
      <MiddleBanner />
      <Signup />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
