import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./components/pages/home/Home";
import TransactionDetails from "./components/pages/transactiondetails/TransactionDetails";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/#signup" element={<Home />} />
          <Route path="/#buysell" element={<Home />} />
          <Route path="/#tokenomics" element={<Home />} />
          <Route path="/transaction" element={<TransactionDetails />} />
          
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
