import React,{useEffect} from "react";
import { useAuth } from "../../../AuthContext";
import { useGetRecentActivityQuery } from "../../../redux/transactionApi";
import moment from "moment";

function RecentActivicity() {
  const {data}=useGetRecentActivityQuery()

  

  return (
    <>
      <div className="rc_box">
        <h4 className="text-center mb-3">RECENT ACTIVITY</h4>
        <div className="rc_box_scrool">
          {data?.length>0?
          data?.map((list)=>{
            return (
              <div className="rc_box_in">
              <div className="rc_box_in_b row">
                <div className="col-md-7">
                  <span className="color1 w100px">UKCCOIN: </span>
                  <span className="">{list.ukc_token} </span>
                </div>
                <div className="col-md-5">
                  <span className="ps-md-2">USD: </span>
                  ${list.usdt_amount}
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <span className="wb w100px">Wallet: </span>
                  <span className="">{list?.from_address && `${list?.from_address?.substring(0, 7)}...${list?.from_address.substring(list?.from_address?.length - 7)}`} </span>
                </div>
                <div className="col-md-5">
                  <span className="ps-md-2">Time: </span>
                  {moment(list.createdAt).format("Do MMM, hh:mm A")}
                </div>
              </div>
            </div>
            )
          }):<h3 className="text-center">No record found</h3>}
        
         
        </div>
      </div>
    </>
  );
}

export default RecentActivicity;
