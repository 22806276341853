import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Emailpattern, Namepattern } from "../../patterns/Pattern";
import { useAuth } from "../../../AuthContext";
import { toast } from "react-toastify";
import { useSetUserRegisterMutation } from "../../../redux/user";

function Signup() {
  const {login, wallet_address } =useAuth();
  const location = useLocation();
  const contactRef = useRef(null);
  const [setUserRegister]=useSetUserRegisterMutation()
  const [mobile_number, setMobile_number] = useState("+44");
  const [number, setNumber] = useState("");
  const [country_code, setCountry_code] = useState();
  const [mobileNumberErr, setMobileNumberErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#signup":
        scrollToRef(contactRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      if (value === "") {
        setEmailErr("This field is required");
      } else if (!Emailpattern.test(value)) {
        setEmailErr("Please enter valid email");
      }
      else{
        setEmailErr("");
      }
    }
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("This field is required");
      } else if (!Namepattern.test(value)) {
        setNameErr("Please enter valid name");
      }
      else{
        setNameErr("");
      }
    }
  };

  const handleOnChanges = (value, country) => {
    setMobile_number(value);
    let adjustedMobile = value?.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setMobileNumberErr("Mobile Number is required");
    } else {
      setMobileNumberErr("");
    }
    setNumber(adjustedMobile);
    setCountry_code("+" + country.dialCode);
  };

  const handleSubmit=(e)=>{
    e.preventDefault()
    if(!localStorage.getItem("wallet_address")){
      toast.dismiss()
      toast.error("Please connect your wallet")
      window.location.href="/#buysell"
      return
    }

    if(name===""){
      setNameErr("This field is required")
      return false
    }
    if (!Namepattern.test(name)){
      setNameErr("Please enter valid name");
      return false
    }
    if(email===""){
      setEmailErr("This field is required")
      return false
    }
    if (!Emailpattern.test(email)){
      setEmailErr("Please enter valid email");
      return false
    }
    if(number===""){
      setMobileNumberErr("This field is required")
      return false
    }
    const data={
      name:name,
      email:email,
      mobile_number:number,
      country_code:country_code,
      wallet_address:localStorage.getItem("wallet_address")
    }
    setUserRegister(data).then((result) => {
      if (result.data.status) {
        login();
        setMobile_number("+44")
        setCountry_code()
        setName("")
        setEmail("")
        setNumber("")
        localStorage.setItem("token", result.data.token);
        toast.dismiss()
        toast.success(result.data.message)
        window.location.href="/#buysell"
      }
    });
  }

  return (
    <>
      <section className="p70 pb-0" ref={contactRef} id="signup">
        <div className="pe-md-4 ">
          <div className=" sign_up position-relative ovh">
            <img
              className="one monone"
              src="/assets/img/one.png"
              alt="pl"
              data-aos="fade-right"
            />
            <img
              className="one dpnone"
              src="/assets/img/one2.png"
              alt="pl"
              data-aos="fade-right"
            />
            <div className="row align-items-center">
              <div
                className="col-md-6 border_right position-relative text-center text-md-start"
                data-aos="fade-up"
              >
                <h2 className="fmenda ">SIGN UP FOR PRESALE!</h2>
                <p className="MendaMed h5">
                  SIGN-UP FOR EXCLUSIVE OFFERS Signup for exclusive offers on
                  token sale, events, merchandising and giveaways. Explore a
                  gateway of opportunities with UKC!
                </p>
              </div>

              <div className="col-md-6" data-aos="fade-left">
                <div className="row">
                  <div className="col-md-5 text-center text-md-left">
                    <img
                      className="img-fluid w100"
                      src="/assets/img/live.png"
                      alt="pl"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="sign_up_box d-flex">
                      <div className="m-auto w100">
                        <div className="form-group ex_input_box position-relative">
                          <label>Name</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Ex: Maguire"
                            className="input_item"
                            value={name}
                            onChange={handleChange}
                          />
                          <span className="text-danger">{nameErr}</span>
                        </div>
                        <div className="form-group ex_input_box position-relative">
                          <label>Email</label>
                          <input
                            type="text"
                            name="email"
                            placeholder="Ex: Maguire@ukccoin.com"
                            className="input_item"
                            value={email}
                            onChange={handleChange}
                          />
                           <span className="text-danger">{emailErr}</span>
                        </div>
                        <div className="form-group ex_input_box position-relative">
                          <label>Phone Number</label>

                          <PhoneInput
                            key={"phoneInput"}
                            country="IND"
                            value={mobile_number}
                            onChange={handleOnChanges}
                            className="input_item2"
                            placeholder="Email/Mobile"
                            countryCodeEditable={false}
                            enableSearch={true}
                            inputProps={{
                              autoFocus: true,
                              name: "mobile_number",
                            }}
                            // disabled={disableGetCode}
                          />
                          <span className="text-danger">{mobileNumberErr}</span>
                        </div>

                        <button
                         
                          className="btn signup_btn w100 mt-3 text-center mb-auto"
                          onClick={handleSubmit}
                        >
                          <span className="d-inline-flex align-items-center">
                            Submit{" "}
                            <i className="bi bi-arrow-right-short b_icon"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
