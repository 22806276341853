import { tronbaseurl } from "./BaseUrl"

//====================== auth ====================
export const checkUserApi="/check-user"
export const userRegisterApi="/user-register"
export const userTempWalletAddressApi="/user-temp-wallet-address"

//================ transaction ===================
export const addTransactinApi="/add-transaction"
export const getTransactionApi="/get-transaction"
export const getRecentActivityApi="/get-recentactivity"

//================= stage ===============
export const getStageApi="/get-stage"

//=============== Gen wallet ===============
export const generateWalletApi="/generate-wallet-address"

//================== Dashboard =================
export const getUserDashboardApi="/get-user-dashboard"