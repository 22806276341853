import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import {
  useGetTransactionQuery,
  useGetUserDashboardQuery,
} from "../../../redux/transactionApi";
import Pagination from "../../constant/Pagination";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import moment from "moment";

function TransactionDetails() {
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const { data } = useGetTransactionQuery();
  const { data: dashboard } = useGetUserDashboardQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (!authenticated) {
      navigate("/");
    }
  }, [authenticated]);

  useEffect(() => {
    scrollTop();
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const totalPages = Math.ceil(data && data?.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Get the data for the current page
  const currentPageData = data && data?.slice(startIndex, endIndex);

  return (
    <>
      <Header />
      <section className="ex_box p70 pn_page_pt exc_sec pb-5">
        <div className="container  position-relative mb-md-5">
          <h2 className="hadding text-center mb-4">
            Transaction <span className="t_gr">Details</span>
          </h2>
          <div className="row text-center">
            <div className="col-md-6 mt-3">
              <div className="box21 p-md-4">
                <h5 className="mb-0">
                  Total Amount Purchased:{dashboard?.total_usdt_amount} USD
                </h5>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="box21 p-md-4">
                <h5 className="mb-0">
                  Total UKC Tokens: {dashboard?.total_ukc}{" "}
                </h5>
              </div>
            </div>
          </div>
          {/* <div className="row text-center">
      <div className="col-md-6 mt-3">
        <div className="box21 p-md-4">
          <h5 className="mb-0">Referral Bonus Token: 0</h5>
        </div>
      </div>
    </div> */}
          <div className="row mt-md-4">
            <div className="col-md-12 mt-3">
              <div className="UKC-middle-box box" id="buy-div">
                <div className="select-currency-wrapper">
                  <div
                    className="wallet-code-disconnects mb-1 text-right"
                    id="conn-status"
                  />
                  <div className="currency-select-wrap mb-1 table_scrool">
                    <div className="table-responsive">
                      <table
                        className="table table-striped "
                        style={{ color: "rgb(255, 255, 255)" }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Wallet Address</th>
                            <th>Transaction Id</th>
                            <th>Chain</th>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Token Amount</th>
                            <th>Status</th>
                            <th>Date Time</th>
                          </tr>
                        </thead>
                        <tbody id="table_body">
                          {currentPageData?.length == 0 && (
                            <tr>
                              <td colSpan={9} className="text-center">
                                No record found
                              </td>
                            </tr>
                          )}
                          {currentPageData?.map((list, index) => {
                            return (
                              <tr key={ list.id}>
                                <td>{(itemsPerPage*(currentPage-1))+ index + 1}</td>
                                <td>{`${list.from_address?.substring(
                                  0,
                                  7
                                )}...${list.from_address?.substring(
                                  list.from_address?.length - 7
                                )}`}</td>
                                <td>{`${list.transaction_id.substring(
                                  0,
                                  8
                                )}...${list.transaction_id.substring(
                                  list.transaction_id.length - 10
                                )}`}</td>
                                <td>{list.chain}</td>
                                <td>{list.currency}</td>
                                <td>{list.amount}</td>
                                <td>{list.ukc_token}</td>
                                <td>{list.status}</td>
                                <td>
                                  {moment(list.createdAt).format(
                                    "Do MMM, hh:mm A"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right mt-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TransactionDetails;
